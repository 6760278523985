import { action } from '@ember/object';
import Router from '@ember/routing/router';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import fade from 'ember-animated/transitions/fade';
import { task, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import isSome from 'mobile-web/lib/utilities/is-some';
import UpsellGroup from 'mobile-web/models/upsell-group';
import UpsellItem from 'mobile-web/models/upsell-item';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import FeaturesService from 'mobile-web/services/features';
import { ProductClickFrom, RecommendationSource } from 'mobile-web/services/global-data';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import GroupOrderService from 'mobile-web/services/group-order';
import NotificationsService, { NotificationType } from 'mobile-web/services/notifications';

import style from './index.m.scss';

interface Args {
  // Required arguments
  upsellGroup: UpsellGroup;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class CartUpsellGroup extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service features!: FeaturesService;
  @service groupOrder!: GroupOrderService;
  @service intl!: IntlService;
  @service router!: Router;
  @service store!: DS.Store;
  @service notifications!: NotificationsService;
  @service globalEvents!: GlobalEventsService;

  // Untracked properties
  transition = fade;
  style = style;

  // Tracked properties
  @tracked rootElement!: HTMLElement;
  @tracked listElement?: HTMLElement;

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks
  addUpsellItemTask = taskFor(this.addUpsellItemTaskInstance);
  @task *addUpsellItemTaskInstance(upsellItem: UpsellItem): TaskGenerator<void> {
    if (this.basket.basket?.groupOrderId) {
      upsellItem.recipientName = this.groupOrder.currentUserName;
    }
    yield this.basket.basket!.addUpsellItem(upsellItem);

    let requiredCount = 0;
    let defaultCount = 0;
    let imageCount = 0;
    let priceSum = 0;
    const priceOfModifier: number[] = [];
    let totalModifiers = 0;
    const product = this.store.peekRecord('product', upsellItem.id);
    if (product) {
      this.globalEvents.trigger(
        GlobalEventName.ClickProductLink,
        {
          ...product.serializeForGlobalData(),
          hasImages: isSome(upsellItem.image),
          hasPrice: true,
        },
        ProductClickFrom.CartUpsell
      );

      product.get('optionGroups')?.forEach(og => {
        if (og.isRequired) requiredCount++;

        og.choices.forEach(ch => {
          if (og.isRequired && ch.get('isDefault')) defaultCount++;
          if (ch.image?.filename) imageCount++;
          const priceDifference: number = ch.get('priceDifference') ?? 0;
          priceSum += priceDifference;
          if (priceDifference > 0) {
            priceOfModifier.push(priceDifference);
          }

          totalModifiers++;
        });
      });
    }

    this.analytics.trackEvent(AnalyticsEvents.AddToCart, () => ({
      [AnalyticsProperties.ProductName]: upsellItem.name,
      [AnalyticsProperties.ProductCategory]: product?.category?.name,
      [AnalyticsProperties.ProductQuantity]: 1,
      [AnalyticsProperties.ProductBasePrice]: upsellItem.cost,
      [AnalyticsProperties.AddToCartMethod]: 'Upsell',
      [AnalyticsProperties.HasVisibleCalories]: isSome(upsellItem.calorieLabel),
      [AnalyticsProperties.VisibleLabels]: upsellItem.labels?.map(l => l.name),
      [AnalyticsProperties.HasProductImages]: isSome(upsellItem.image),
      [AnalyticsProperties.HasCategoryImages]: !isEmpty(product?.category?.images),
      [AnalyticsProperties.IsFeatured]: product?.isFeatured ?? false,
      [AnalyticsProperties.QuickAddSupported]: product?.quickAddSupported,
      [AnalyticsProperties.RecommendationModelVersion]: this.args.upsellGroup?.modelVersion,
      [AnalyticsProperties.RecommendationRecentProductCount]:
        this.args.upsellGroup?.recentProductCount ?? 0,
      [AnalyticsProperties.RecommendationSource]: RecommendationSource.CartCrossSell,
      [AnalyticsProperties.NumberOfTotalMods]: totalModifiers,
      [AnalyticsProperties.NumberOfRequiredMods]: requiredCount,
      [AnalyticsProperties.NumberOfDefaultedMods]: defaultCount,
      [AnalyticsProperties.NumberOfPricedMods]: priceOfModifier,
      [AnalyticsProperties.NumberOfImageMods]: imageCount,
      [AnalyticsProperties.SumOfModPrices]: priceSum,
    }));

    const message = this.intl.t('mwc.notifications.added', {
      quantity: upsellItem.quantity,
    });

    this.notifications.success({
      message,
      type: NotificationType.UpsellAdded,
    });

    this.basket.getSmartUpsellDataIfVisible.perform();
  }

  // Actions and helpers

  @action
  async addUpsellItem(upsellItem: UpsellItem) {
    const product = await this.store.peekRecord('product', upsellItem.id);

    if (product?.hasChoices) await product?.hasMany('optionGroups').load();

    const hasRequiredModifiers =
      product?.hasChoices && product?.optionGroups?.any(x => x.isRequired);

    const hasDefaultsForRequiredModifiers =
      hasRequiredModifiers &&
      product?.optionGroups?.every(
        x => !x.isRequired || x.minSelects <= x.choices?.filter(y => y.isDefault).length
      );

    this.analytics.trackEvent(AnalyticsEvents.AddUpsellIntent, () => ({
      [AnalyticsProperties.ProductName]: upsellItem.name,
      [AnalyticsProperties.ProductCategory]: product?.category?.name,
      [AnalyticsProperties.UpsellGroupName]: this.args.upsellGroup.title,
      [AnalyticsProperties.ProductBasePrice]: upsellItem.cost,
      [AnalyticsProperties.HasVisibleCalories]: isSome(upsellItem.calorieLabel),
      [AnalyticsProperties.VisibleLabels]: upsellItem.labels?.map(l => l.name),
      [AnalyticsProperties.HasProductImages]: isSome(upsellItem.image),
      [AnalyticsProperties.QuickAddSupported]: product?.quickAddSupported,
      [AnalyticsProperties.IsSmartUpsell]: this.args.upsellGroup?.id === 'smart',
      [AnalyticsProperties.UpsellPosition]: this.args.upsellGroup?.items?.indexOf(upsellItem),
      [AnalyticsProperties.UpsellItemsInGroupCount]: this.args.upsellGroup?.items?.length,
      [AnalyticsProperties.HasRequiredModifiers]: hasRequiredModifiers,
      [AnalyticsProperties.HasDefaultsForRequiredModifiers]: hasDefaultsForRequiredModifiers,
      [AnalyticsProperties.RecommendationModelVersion]: this.args.upsellGroup?.modelVersion,
      [AnalyticsProperties.RecommendationRecentProductCount]:
        this.args.upsellGroup?.recentProductCount ?? 0,
    }));

    if (
      this.basket.enableSmartUpsellMoreQuickAdd &&
      (!hasRequiredModifiers || hasDefaultsForRequiredModifiers)
    ) {
      // no required modifiers, or the required modifiers have defaults, so quick-add is supported for upsell
    } else if (product && !product.quickAddSupported) {
      this.router.transitionTo('menu.vendor.products', product.vendor.slug, product.id, {
        queryParams: { clickFrom: ProductClickFrom.CartUpsell },
      });
      // hide the cart to show the modifier page behind it (in case the route is not different)
      this.basket.close();
      return;
    }

    this.addUpsellItemTask.perform(upsellItem);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cart::UpsellGroup': typeof CartUpsellGroup;
  }
}
