import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import DS from 'ember-data';

import { GlobalBasket } from 'mobile-web/models/basket';
import { GlobalDevice } from 'mobile-web/models/device';
import { GlobalOrder } from 'mobile-web/models/order';
import { GlobalProduct } from 'mobile-web/models/product';
import { GlobalUser } from 'mobile-web/models/user';
import { GlobalVendor } from 'mobile-web/models/vendor';
import BasketService from 'mobile-web/services/basket';
import DeviceService from 'mobile-web/services/device';
import ErrorService from 'mobile-web/services/error';
import SessionService from 'mobile-web/services/session';
import VendorService from 'mobile-web/services/vendor';

export interface GlobalData {
  vendor?: GlobalVendor;
  basket?: GlobalBasket;
  product?: GlobalProduct;
  order?: GlobalOrder;
  user?: GlobalUser;
  device?: GlobalDevice;
}

export enum ProductClickFrom {
  CartUpsell = 'cart-upsell',
  Category = 'category',
  SingleUse = 'single-use',
  RecentItem = 'recent-item',
  VendorMenu = 'vendor-menu',
}

export enum RecommendationSource {
  CartCrossSell = 'cart-cross-sell',
  RecentProduct = 'recent-product',
  RecentOrder = 'recent-order',
  MostOrdered = 'most-ordered',
  MenuCrossSell = 'menu-cross-sell',
}

export function toRecommendationSource(
  clickFrom: ProductClickFrom | undefined
): RecommendationSource | undefined {
  type PartialMappingType = {
    [key in ProductClickFrom]?: RecommendationSource;
  };

  const partialMapping: PartialMappingType = {
    [ProductClickFrom.CartUpsell]: RecommendationSource.CartCrossSell,
    [ProductClickFrom.RecentItem]: RecommendationSource.RecentProduct,
    // TODO: Add most-ordered when that becomes available
  };

  return clickFrom && partialMapping[clickFrom];
}

function deepFreeze<T>(obj: T): T | undefined {
  if (!obj) return undefined;

  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(obj) as Array<keyof T>;

  // Freeze properties before freezing self
  for (const name of propNames) {
    const value: unknown = obj[name];

    if (value && typeof value === 'object') {
      deepFreeze(value);
    }
  }

  return Object.freeze(obj);
}

export default class GlobalDataService extends Service {
  // Service injections
  @service('basket') basketService!: BasketService;
  @service('vendor') vendorService!: VendorService;
  @service('router') routerService!: RouterService;
  @service('error') errorService!: ErrorService;
  @service('session') sessionService!: SessionService;
  @service('device') deviceService!: DeviceService;
  @service store!: DS.Store;

  // Untracked properties
  private initialized = false;

  // Tracked properties

  // Getters and setters
  get data(): GlobalData {
    return window.Olo.data;
  }

  set data(data: GlobalData) {
    window.Olo = window.Olo || {};
    window.Olo.data = data;
  }

  // Lifecycle methods

  // Other methods
  updateVendor(): void {
    this.updateData('vendor', deepFreeze(this.vendorService?.vendor?.serializeForGlobalData()));
  }

  updateBasket(): void {
    this.updateData('basket', deepFreeze(this.basketService?.basket?.serializeForGlobalData()));
  }

  updateUser(): void {
    this.updateData('user', deepFreeze(this.sessionService?.serializeUserForGlobalData()));
  }

  updateDevice(): void {
    this.updateData('device', deepFreeze(this.deviceService?.serializeDeviceForGlobalData()));
  }

  /**
   * Update the product object by pulling data from the store.
   */
  updateProduct(): void {
    const { name, paramNames, params } = this.routerService.currentRoute || {};
    if (name === 'menu.vendor.products' && paramNames.includes('product_id')) {
      try {
        const product = this.store.peekRecord('product', params.product_id!)!;
        this.updateData(
          'product',
          product ? deepFreeze(product.serializeForGlobalData()) : undefined
        );
      } catch (e) {
        this.updateData('product', undefined);
        this.errorService.sendExternalError(e);
      }
    } else {
      this.updateData('product', undefined);
    }
  }

  /**
   * Update the order object by pulling data from the store.
   */
  updateOrder(): void {
    const { name, paramNames, params } = this.routerService.currentRoute || {};
    if (name === 'thank-you' && paramNames.includes('order_id')) {
      try {
        const order = this.store.peekRecord('order', params.order_id!)!;
        this.updateData('order', order ? deepFreeze(order.serializeForGlobalData()) : undefined);
      } catch (e) {
        this.updateData('order', undefined);
        this.errorService.sendExternalError(e);
      }
    } else {
      this.updateData('order', undefined);
    }
  }

  /**
   * Create a sealed data object so that the shape of it cannot be modified.
   * Listen for changes to services and then update the data.
   */
  setup(): void {
    if (this.initialized) {
      return;
    }
    this.initialized = true;

    this.updateData({
      basket: undefined,
      vendor: undefined,
      product: undefined,
      order: undefined,
      device: undefined,
      user: undefined,
    });
    this.setupVendorObservers();
    this.setupProductObservers();
    this.setupOrderObservers();
    this.setupBasketObservers();
    this.setupSessionObservers();
    this.updateDevice();
  }

  private setupVendorObservers() {
    this.vendorService.addObserver('vendor', this, this.updateVendor); // eslint-disable-line ember/no-observers
    this.updateVendor();
  }

  private setupProductObservers() {
    this.routerService.on('routeDidChange', this.updateProduct.bind(this));
    this.updateProduct();
  }

  private setupOrderObservers() {
    this.routerService.on('routeDidChange', this.updateOrder.bind(this));
    this.updateOrder();
  }

  /**
   * Listen for changes to the basket or its products.
   * Because the `basketProducts` array can be nuked, each time the basket itself changes
   * we need to remove and re-add listeners to the last known instance of `basketProducts`.
   */
  private setupBasketObservers() {
    this.basketService.addObserver('basket', this, this.updateBasket.bind(this)); // eslint-disable-line ember/no-observers
    this.updateBasket();
  }

  private setupSessionObservers() {
    // Observers don't work on plain getters that depend on tracked properties;
    // we have to observe the dependent tracked properties directly
    this.sessionService.addObserver('currentUser', this, this.updateUser); // eslint-disable-line ember/no-observers
    this.sessionService.addObserver('localGuestUser', this, this.updateUser); // eslint-disable-line ember/no-observers
    this.updateUser();
  }

  /**
   * Update data on the window.
   * Tried doing this in a less verbose way by adding the `@tracked` annotation to
   * these properties and using the `@observes` decorator, but this causes the whole application
   * to take longer to initialize for some reason and that breaks all sorts of tests.
   */
  private updateData(vals: GlobalData): void;
  private updateData<K extends keyof GlobalData>(key: K, val: GlobalData[K]): void;
  private updateData(...args: unknown[]) {
    const vals = args.length === 1 ? (args[0] as UnknownObject) : { [args[0] as string]: args[1] };
    this.data = Object.seal({ ...this.data, ...vals });
    // eslint-disable-next-line ember/classic-decorator-no-classic-methods
    this.notifyPropertyChange('data');
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    'global-data': GlobalDataService;
  }
}
